import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MainWidgetTextField } from 'views/common/components/UI/MainWidget/MainWidgetTextField';

const StyledGrid = styled(Grid)(({ theme, bordered }) => ({
  width: '100%',
  backgroundColor: theme.palette.common.white,
  border: bordered ? `1px solid ${theme.palette.grey[100]}` : null,
  [theme.breakpoints.down('md')]: {
    border: `1px solid ${theme.palette.grey[100]}`,
  },
  '& .WidgetDateField': {
    '& .MuiButtonBase-root': {
      '& .MuiSvgIcon-root': {
        visibility: 'hidden',
      },
    },
  },
}));

export const SelectMainWidgetTextField = styled(MainWidgetTextField)(
  ({ theme }) => ({
    '& .MuiFilledInput-root': {
      borderLeft: `1px solid ${theme.palette.grey[100]}`,
      '&.Mui-disabled': {
        backgroundColor: `${theme.palette.action.disabledBackground} !important`,
      },
    },
    '& .MuiSelect-icon': {
      display: 'none',
    },
  }),
);

export default StyledGrid;
