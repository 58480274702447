import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { localeDateFormatMap } from 'lib/config/date-picker';
import { mapNamedLocaleToLocale } from 'lib/data/localesMap';
import {
  StyledMainWidgetTextField,
  StyledMuiDatePicker,
} from 'views/common/components/UI/MainWidget/MainWidgetDatePicker/MainWidgetDatePicker.styles';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs as DateAdapter } from '@mui/x-date-pickers/AdapterDayjs';
import deLocale from 'dayjs/locale/de';
import frLocale from 'dayjs/locale/fr';
import esLocale from 'dayjs/locale/es';
import itLocale from 'dayjs/locale/it';
import ptLocale from 'dayjs/locale/pt';

const mapNamedLocaleToDayJsLocale = {
  'motorrad-mieten': deLocale,
  'location-moto': frLocale,
  'alquiler-de-motos': esLocale,
  'noleggio-moto': itLocale,
  'aluguel-de-motos': ptLocale,
};

/**
 * Shared DatePicker that takes care of date format based on the locale
 * It also positioning the datepicker based on the media query
 */

const MainWidgetDatePicker = (props) => {
  const {
    error,
    helperText,
    className,
    id,
    vertical,
    onChange,
    disabled,
    openMainWidgetDatePicker = false,
    resetOpenWidgetDateTimeField = () => {},
    ...otherProps
  } = props;
  const appNavigator = useAppNavigator();
  const [open, setOpen] = useState(false);
  const inputRef = useRef();
  inputRef?.current?.blur();
  useEffect(() => {
    dayjs.locale(mapNamedLocaleToLocale[appNavigator.locale].toLowerCase());
    return () => {
      dayjs.locale(mapNamedLocaleToLocale.en.toLowerCase());
    };
  }, []);

  useEffect(() => {
    setOpen(!!openMainWidgetDatePicker);
  }, [openMainWidgetDatePicker]);

  return (
    <Box position="relative">
      <LocalizationProvider
        dateAdapter={DateAdapter}
        adapterLocale={mapNamedLocaleToDayJsLocale[appNavigator.locale]}>
        <StyledMuiDatePicker
          open={open}
          onOpen={() => {
            if (!disabled) {
              setOpen(true);
            }
          }}
          format={localeDateFormatMap[appNavigator.locale]}
          onClose={() => {
            setOpen(false);
            resetOpenWidgetDateTimeField();
          }}
          onChange={(...args) => {
            if (document.getElementsByClassName('MuiPickersDay-root')?.length) {
              onChange(...args);
              setOpen(false);
              resetOpenWidgetDateTimeField();
            }
          }}
          margin="none"
          inputRef={inputRef}
          disabled={disabled}
          slots={{
            textField: StyledMainWidgetTextField,
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              bordered: vertical,
              margin: 'none',
              variant: 'filled',
              helperText,
              error,
              id,
              onClick: () => {
                if (!disabled) {
                  setOpen(true);
                }
              },
            },
            popper: {
              placement: 'bottom-start',
              'data-testid': otherProps.label,
            },
            dialog: {
              'data-testid': otherProps.label,
            },
          }}
          classes={{ root: className }}
          {...otherProps}
        />
      </LocalizationProvider>
    </Box>
  );
};

export { MainWidgetDatePicker };
