import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { MainWidgetTextField } from 'views/common/components/UI/MainWidget';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import React from 'react';

export const StyledMainWidgetTextField = styled(MainWidgetTextField)(
  ({ theme }) => ({
    '& .MuiInputBase-input.MuiFilledInput-input': {
      paddingLeft: `${theme.spacing(6.2)} !important`,
    },
    '& .MuiInputAdornment-root': {
      display: 'none',
    },
    '& .MuiFilledInput-root': {
      '&.Mui-disabled': {
        backgroundColor: `${theme.palette.action.disabledBackground} !important`,
      },
    },
  }),
);

export const StyledMuiDatePicker = ({ whiteLabelWidget, ...props }) => {
  const newTheme = (theme) => {
    const baseTheme = {
      ...theme,
      components: {
        MuiPickersDay: {
          styleOverrides: {
            root: {
              fontSize: theme.typography.pxToRem(14),
              fontWeight: 400,
            },
          },
        },
        MuiDayCalendar: {
          styleOverrides: {
            weekDayLabel: {
              fontSize: theme.typography.pxToRem(14),
              fontWeight: 700,
            },
          },
        },
        MuiPickersToolbar: {
          styleOverrides: {
            root: {
              display: 'none',
            },
          },
        },
      },
    };

    if (whiteLabelWidget) {
      baseTheme.components.MuiDialog = {
        styleOverrides: {
          paper: {
            height: 'auto',
            maxHeight: `calc(100% - ${theme.spacing(1)})`,
            maxWidth: 'unset',
            '& .MuiPickersSlideTransition-root': {
              minHeight: theme.spacing(28.75),
            },
            '& .MuiPickersLayout-root': {
              height: '100%',
            },
            '& .MuiPickersCalendarHeader-root': {
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(1),
            },
            '& .MuiDialogActions-root': {
              paddingTop: theme.spacing(0.5),
              paddingBottom: theme.spacing(0.5),
            },
          },
        },
      };
    }

    return createTheme(baseTheme);
  };

  return (
    <ThemeProvider theme={newTheme}>
      {whiteLabelWidget ? (
        <MobileDatePicker {...props} />
      ) : (
        <MuiDatePicker {...props} />
      )}
    </ThemeProvider>
  );
};
