import React, { useState } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import StyledButtonGroup from 'views/common/components/UI/MainWidget/VehicalCounter/VehicalCounter.styles';
import { RENTAL_WIDGET_FIELDS } from 'lib/utils/mainWidgetConstants';
import Typography500 from 'views/common/components/UI/Typography500';

const VehicalCounter = (props) => {
  const { vehicleCount = 6, handleFormDataChange, className } = props;
  const [count, setCount] = useState(vehicleCount < 6 ? 6 : vehicleCount);
  return (
    <StyledButtonGroup className={className}>
      <Button
        color="secondary"
        className="VCBtn"
        variant="outlined"
        onClick={() => {
          setCount((prev) => prev - 1);
          handleFormDataChange(RENTAL_WIDGET_FIELDS.VEHICLES_COUNT, +count - 1);
        }}
        disabled={count === 6}>
        <RemoveIcon fontSize="small" />
      </Button>
      <Typography500 variant="body" className="VCCountNum">
        {count}
      </Typography500>
      <Button
        color="secondary"
        className="VCBtn"
        variant="outlined"
        onClick={() => {
          setCount((prev) => prev + 1);
          handleFormDataChange(RENTAL_WIDGET_FIELDS.VEHICLES_COUNT, +count + 1);
        }}>
        <AddIcon fontSize="small" />
      </Button>
    </StyledButtonGroup>
  );
};

export { VehicalCounter };
