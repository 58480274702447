import React from 'react';
import dynamic from 'next/dynamic';
import { WidgetTitle } from 'views/common/components/UI/MainWidget/WidgetTitle';
import { RENTAL_WIDGET, TOUR_WIDGET } from 'lib/utils/mainWidgetConstants';
import StyledBox from 'views/common/components/UI/MainWidget/MainWidget.styles';
import { RentalWidget } from 'views/common/components/UI/MainWidget';

const AnnouncementBar = dynamic(() => import('views/common/components/UI/AnnouncementBar').then(
  (file) => file.AnnouncementBar,
));

const WidgetAlert = dynamic(() => import('views/common/components/UI/MainWidget/WidgetAlert').then(
  (file) => file.WidgetAlert,
));
const TourWidget = dynamic(() => import('views/common/components/UI/MainWidget/TourWidget').then(
  (file) => file.TourWidget,
));

const rentalWidgetDummyData = {
  pickup: { locationsDummyData: [], time_slots: [] },
  dropoff: { locationsDummyData: [], time_slots: [] },
  locationsDummyData_geographical_data: [],
};
const locationsDummyData = {
  pickup: [],
  dropoff: [],
};
const formData = {
  reservation: { vehicles_count: 1 },
};
const initTourWidgetData = {
  selectedCountry: null,
  selectedCity: null,
  selectedTour: null,
  countries: [],
  cities: [],
  tours: [],
};
const MainWidgetForCrawlers = (props) => {
  const {
    shouldHideTitle,
    vertical,
    className,
    alertMassage,
    titleColor,
    announcementBar,
    widgetInfo,
    handleWidgetMenuSelect,
    renderAllWidget = true,
    handleRenderAllWidget = () => {},
    ...other
  } = props;
  // eslint-disable-next-line consistent-return
  const renderAnnouncment = () => {
    if (announcementBar) {
      return <AnnouncementBar vertical={vertical} />;
    }
  };
  const renderWidget = () => {
    const rentalWidget = (
      <RentalWidget
        pickupData={{
          locationsDummyData: locationsDummyData.pickup,
          minDate: rentalWidgetDummyData.pickup.min_date,
          maxDate: rentalWidgetDummyData.pickup.max_date,
          timeSlots: rentalWidgetDummyData.pickup.time_slots,
        }}
        dropoffData={{
          locationsDummyData: locationsDummyData.dropoff,
          minDate: rentalWidgetDummyData.dropoff.min_date,
          maxDate: rentalWidgetDummyData.dropoff.max_date,
          timeSlots: rentalWidgetDummyData.dropoff.time_slots,
        }}
        motorcycleList={[
          {
            number: '1',
          },
          {
            number: '2',
            discount: '-6',
          },
          {
            number: '3',
            discount: '-9',
          },
          {
            number: '4',
            discount: '-13',
          },
          {
            number: '5',
            discount: '-17',
          },
          {
            number: '6+',
            discount: '-20',
          },
        ]}
        handleLocationInputChange={() => {}}
        formData={formData}
        handleFormDataChange={() => {}}
        handleWidgetTypeChange={() => {}}
        shouldDisableDate={() => {}}
        returnToDifferentLocation
        handleReturnToDifferentLocation={() => {}}
        isOptionsLoading={false}
        handleSearchButtonClick={() => {}}
        handleRenderAllWidget={handleRenderAllWidget}
        errors={{}}
        vertical={vertical}
        renderAllWidget={false}
        {...other}
      />
    );
    switch (widgetInfo.type) {
      case RENTAL_WIDGET:
        return rentalWidget;
      case TOUR_WIDGET:
        return (
          <TourWidget
            citiesList={initTourWidgetData.cities}
            countriesList={initTourWidgetData.countries}
            toursList={initTourWidgetData.tours}
            handleSelectInputChange={() => {}}
            selectedOptions={{}}
            handleSearchButtonClick={() => {}}
            {...props}
          />
        );
      default:
        return rentalWidget;
    }
  };
  return (
    <StyledBox
      className={`mainWidget ${className}`}
      renderAllWidget={renderAllWidget}>
      {!shouldHideTitle && (
        <WidgetTitle
          widgetInfo={widgetInfo}
          vertical={vertical}
          titleColor={titleColor}
          handleWidgetMenuSelect={handleWidgetMenuSelect}
        />
      )}
      {alertMassage && <WidgetAlert alertMassage={alertMassage} />}
      {renderWidget()}
      {renderAllWidget && renderAnnouncment()}
    </StyledBox>
  );
};

export { MainWidgetForCrawlers };
