import { TextField, styled } from '@mui/material';

export const MainWidgetTextField = styled(TextField)(
  ({ theme, isFocused }) => ({
    height: '100%',
    '& label': {
      left: theme.spacing(4.7),
      color: theme.palette.grey[200],
    },
    '& .MuiFilledInput-root': {
      backgroundColor: theme.palette.common.white,
      borderBottom: 0,
      borderRadius: 0,
      height: '100%',
      '&:before, :after': {
        borderColor: 'transparent',
      },
      '&:hover': {
        backgroundColor: theme.palette.common.white,
        '&::before, ::after': {
          borderColor: 'transparent !important',
        },
      },
      '&.Mui-disabled': {
        backgroundColor: `${theme.palette.action.disabledBackground} !important`,
      },
      '&.Mui-focused': {
        backgroundColor: theme.palette.common.white,
      },
      '&.Mui-error': {
        '&:before, :after': {
          borderColor: theme.palette.error.main,
        },
        '&:hover': {
          '&::before, ::after': {
            borderColor: theme.palette.error.main,
          },
        },
      },
      '& .MuiInputBase-input.MuiFilledInput-input': {
        paddingLeft: theme.spacing(5.5),
        color: theme.palette.secondary.dark,
        '&::placeholder': {
          opacity: 1,
          color: isFocused ? theme.palette.grey[300] : theme.palette.grey[700],
        },
        '&.MuiSelect-select': {
          padding: theme.spacing(1, 1, 1, 5.6),
        },
      },
    },
  }),
);
