import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Box,
  useMediaQuery,
  useTheme,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'next-i18next';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {
  WidgetDateTimeField,
  VehicleCount,
  WidgetNotificationBox,
} from 'views/common/components/UI/MainWidget';
import { WidgetLocationField } from 'views/common/components/UI/MainWidget/WidgetLocationField';
import { LOCATION_TYPE } from 'lib/utils/mainWidgetConstants';
import StyledGrid, {
  ShiftedLocationFeild,
} from 'views/common/components/UI/MainWidget/RentalWidget/RentalWidget.styles';

const { envVars } = require('next/config').default().publicRuntimeConfig;

const SHIFT_AMOUNT = 10;

const RentalWidget = (props) => {
  const { t } = useTranslation('fe_er_common_main_widget');
  const {
    vertical,
    pickupData,
    dropoffData,
    motorcycleList,
    handleLocationInputChange,
    formData,
    handleFormDataChange,
    shouldDisableDate,
    returnToDifferentLocation,
    handleReturnToDifferentLocation,
    isOptionsLoading,
    handleSearchButtonClick,
    renderAllWidget = true,
    handleRenderAllWidget = () => {},
    bordered,
    rentalWidgetNotifications,
    disableVehicleCount,
    actionButtonCustomLabel,
    errors,
    disabled,
    hasToReturnToDifferentLocation,
    isDealWidget,
    hiddenVehicleCount,
    hiddenSearchBtn,
    openDropoffDatePicker,
    resetOpenDropoffDatePicker,
    recaptchaRef,
    recaptchaVehicleCountThreshold,
    whiteLabelWidget = false,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [widgetMounted, setWidgetMounted] = useState(false);

  const handleSearch = (recaptchaToken) => {
    if (renderAllWidget) {
      handleSearchButtonClick(recaptchaToken);
    } else {
      handleRenderAllWidget(true);
    }
  };

  const handleRecaptchaButtonClick = (e) => {
    e.preventDefault();

    if (
      !hiddenSearchBtn
      && formData.reservation.vehicles_count >= recaptchaVehicleCountThreshold
      && renderAllWidget
    ) {
      recaptchaRef.current.execute();
    } else {
      handleSearch();
    }
  };

  useEffect(() => {
    setWidgetMounted(true);
  }, []);

  useEffect(() => {
    if (widgetMounted) {
      if (isMobile) {
        if (formData.dataFromQuery) {
          handleReturnToDifferentLocation(true, true);
          if (
            formData.reservation?.pickup_location_id
            == formData.reservation?.dropoff_location_id
          ) {
            handleReturnToDifferentLocation(false);
          }
          handleRenderAllWidget(true);
        } else {
          handleReturnToDifferentLocation(false);
        }
      } else {
        handleReturnToDifferentLocation(true, true);
        handleRenderAllWidget(true);
      }
    }
  }, [widgetMounted, isMobile]);

  return (
    <StyledGrid container rowSpacing={whiteLabelWidget ? 1 : 2}>
      {(rentalWidgetNotifications?.[0] || errors?.reservation_widget_base) && (
        <Grid item xs={12}>
          {errors?.reservation_widget_base && (
            <Box>
              <WidgetNotificationBox
                title={t('fe_er_common_main_widget:base_error_header')}
                notificationsList={errors?.reservation_widget_base}
              />
            </Box>
          )}
          {rentalWidgetNotifications?.[0] && (
            <Box>
              <WidgetNotificationBox
                notificationsList={rentalWidgetNotifications}
              />
            </Box>
          )}
        </Grid>
      )}
      <Grid container item spacing={vertical ? 1.5 : { md: 0.5, xs: 1.5 }}>
        <Grid item xs={12} md={vertical ? 12 : 6} position="relative">
          <WidgetLocationField
            pickup={LOCATION_TYPE.PICKUP}
            selectedLocationId={formData.reservation?.pickup_location_id}
            locationsList={pickupData.locations}
            vertical={vertical}
            bordered={bordered}
            label={t('fe_er_common_main_widget:pickup_location')}
            placeholder={t('fe_er_common_main_widget:choose_pickup_location')}
            handleInputChange={handleLocationInputChange}
            handleLocationChange={handleFormDataChange}
            handleRenderAllWidget={handleRenderAllWidget}
            loading={isOptionsLoading[LOCATION_TYPE.PICKUP]}
            errors={errors}
            hasArrow="true"
            disabled={disabled}
          />
          {!vertical && (
            <Box className="RWArrowIcon">
              <ArrowRightAltIcon />
            </Box>
          )}
        </Grid>
        {renderAllWidget && (
          <>
            {!whiteLabelWidget && (
              <Grid item xs={12} className="RWCheckBox">
                <Box>
                  <FormGroup>
                    <FormControlLabel
                      checked={returnToDifferentLocation}
                      onChange={(e) => handleReturnToDifferentLocation(e.target.checked)}
                      control={<Checkbox defaultChecked />}
                      label={t(
                        'fe_er_common_main_widget:return_to_different_location',
                      )}
                      disabled={disabled}
                    />
                  </FormGroup>
                </Box>
              </Grid>
            )}
            {(returnToDifferentLocation || whiteLabelWidget) && (
              <Grid item xs={12} md={vertical ? 12 : 6}>
                <ShiftedLocationFeild
                  SHIFT_AMOUNT={SHIFT_AMOUNT}
                  pickup={LOCATION_TYPE.DROPOFF}
                  selectedLocationId={formData.reservation?.dropoff_location_id}
                  locationsList={dropoffData.locations}
                  vertical={vertical}
                  bordered={bordered}
                  hasArrow="false"
                  shiftAmount={SHIFT_AMOUNT}
                  label={t('fe_er_common_main_widget:dropoff_location')}
                  placeholder={
                    hasToReturnToDifferentLocation
                      ? t('fe_er_common_main_widget:choose_dropoff_location')
                      : t('fe_er_common_main_widget:same_as_pickup_location')
                  }
                  handleInputChange={handleLocationInputChange}
                  handleLocationChange={handleFormDataChange}
                  loading={isOptionsLoading[LOCATION_TYPE.DROPOFF]}
                  errors={errors}
                  disabled={disabled}
                  isDealWidget={isDealWidget}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Grid
        container
        item
        rowSpacing={whiteLabelWidget ? 1.5 : 2}
        columnSpacing={1.5}
        className="RWDateTimeGrid">
        {renderAllWidget && (
          <>
            <Grid item xs={12} md={vertical ? 12 : 6}>
              <WidgetDateTimeField
                pickup={LOCATION_TYPE.PICKUP}
                vertical={vertical}
                bordered={bordered}
                timeList={pickupData.timeSlots}
                label={t('fe_er_common_main_widget:pickup_date_label')}
                date={formData.reservation?.pickup_date}
                time={formData.reservation?.pickup_time_slot}
                minDate={pickupData.minDate}
                maxDate={pickupData.maxDate}
                handleDateTimeChange={handleFormDataChange}
                shouldDisableDate={shouldDisableDate}
                errors={errors}
                disabled={disabled}
                whiteLabelWidget={whiteLabelWidget}
              />
            </Grid>
            <Grid item xs={12} md={vertical ? 12 : 6}>
              <WidgetDateTimeField
                pickup={LOCATION_TYPE.DROPOFF}
                vertical={vertical}
                bordered={bordered}
                timeList={dropoffData.timeSlots}
                label={t('fe_er_common_main_widget:dropoff_date_label')}
                date={formData.reservation?.dropoff_date}
                time={formData.reservation?.dropoff_time_slot}
                minDate={dropoffData.minDate}
                maxDate={dropoffData.maxDate}
                handleDateTimeChange={handleFormDataChange}
                shouldDisableDate={shouldDisableDate}
                errors={errors}
                disabled={disabled}
                openWidgetDateTimeField={openDropoffDatePicker}
                resetOpenWidgetDateTimeField={resetOpenDropoffDatePicker}
                whiteLabelWidget={whiteLabelWidget}
              />
            </Grid>
            {!hiddenVehicleCount && (
              <Grid item xs={12} md={vertical ? 12 : 6}>
                <VehicleCount
                  errors={errors}
                  disableVehicleCount={disableVehicleCount || disabled}
                  handleFormDataChange={handleFormDataChange}
                  formData={formData}
                  vertical={vertical}
                  bordered={bordered}
                  motorcycleList={motorcycleList}
                />
              </Grid>
            )}
          </>
        )}
        {!hiddenSearchBtn && (
          <Grid item xs={12} md={vertical ? 12 : 6}>
            <Button
              variant="contained"
              className="RWSearchNowButton"
              id="rentalWidgetSearchNowBtn"
              fullWidth
              size="large"
              disableElevation
              disabled={disabled}
              onClick={handleRecaptchaButtonClick}>
              {actionButtonCustomLabel
                || (renderAllWidget
                  ? t('fe_er_common_main_widget:search_now')
                  : t('fe_er_common_main_widget:start_your_reservation'))}
            </Button>
          </Grid>
        )}
        {envVars.INVISIBLE_RECAPTCHA_SITE_KEY
          && !hiddenSearchBtn
          && formData.reservation.vehicles_count
            >= recaptchaVehicleCountThreshold
          && renderAllWidget && (
            <ReCAPTCHA
              className="SACRecaptchaBadge"
              ref={recaptchaRef}
              sitekey={envVars.INVISIBLE_RECAPTCHA_SITE_KEY}
              size="invisible"
              onChange={(recaptchaToken) => {
                handleSearch(recaptchaToken);
              }}
            />
        )}
      </Grid>
    </StyledGrid>
  );
};

export { RentalWidget };
